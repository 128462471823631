export const APPS_URLS = 'appsUrls'
export const MODELS_API = 'modelsApi'
export const PLATFORM_BI = 'platformBi'
export const BOOTSTRAP_DATA = 'bootstrapData'
export const IMPORT_SCRIPTS = 'importScripts'
export const INSTANCE_CACHE = 'instanceCache'
export const APPS_PUBLIC_API = 'appsPublicApi'
export const SSR_CACHE_HINTS = 'ssrCacheHints'
export const PLATFORM_LOGGER = 'platformLogger'
export const SESSION_SERVICE = 'sessionService'
export const VIEWER_HANDLERS = 'viewerHandlers'
export const UNFINISHED_TASKS = 'unfinishedTasks'
export const FEDOPS_WEB_VITALS = 'fedopsWebVitals'
export const CONTROLLER_EVENTS = 'controllerEvents'
export const PLATFORM_BI_LOGGER = 'platformBiLogger'
export const CLIENT_SPEC_MAP_API = 'clientSpecMapApi'
export const MODELS_API_PROVIDER = 'modelsApiProvider'
export const PLATFORM_ESSENTIALS = 'platformEssentials'
export const INVOKE_VIEWER_HANDLER = 'invokeViewerHandler'
export const COMPONENT_SDKS_MANAGER = 'componentSdksManager'
export const MODELS_PROVIDER_FACTORY = 'modelsProviderFactory'
export const LOAD_COMPONENT_SDKS_PROMISE = 'loadComponentSdksPromise'
